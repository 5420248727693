import React from "react"
//COMPONENTS
import Article from "../components/article"
import Section from "../components/section"
import BulletItem from "../components/bullet-item"
import About from "../components/about"
import Newsletter from "../components/newsletter"
import Navigation from "../components/navigation"

//IMAGES
//import ImageTurkisWay from "./assets/jailam-rashad-hSFgNXOztLU-unsplash.jpg"
import ImageCover from "./assets/pexels-pixabay-434337.jpg"

import ImageConfianza2 from "./assets/CONFIDENCE-pexels-moose-photos-1036622-2.jpg"

//ICONS
import { BsCheckCircle } from "react-icons/Bs"
import Footer from "../components/footer"

// this is how you use the icon directly: listItemIcon={<iconName/>}
// or you declar the name you want to use: const myIconName = <TiChevronRightOutline size="20px"/>
export default function TRTV() {
  return (
    <>
      <Navigation active={-1} />

      <Section
        className="playfair font-size-verylarge"
        color="turkis-blue1"
        bgImage={ImageCover}
        overlay="full"
        //overlayColor="rgba(250, 246, 246,.4)"
        overlayColor="rgba(116, 194, 200,.6)"
      >
        <Article
          layout="narrow"
          title="TEST: “¿Reconoces tu valor y confías REALMENTE en ti misma?”"
          titleClassName="tenor white font-size-extralarge lineheight-large"
        ></Article>
      </Section>

      <Section color="white">
        <Article className="narrow   center font-size-small  lineheight-verylarge">
          ¿Sufres las consecuencias de no saber reconocer tu valor o tus
          talentos
          <br /> y no te has dado cuenta?
          <br />
          ¿Menosprecias tus habilidades y tu experiencia acumulada?
          <br />
          ¿Padeces de falta de confianza en ti misma
          <br /> pero no crees que sea así?
        </Article>

        <Article className="narrow tenor bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
          ¡Averígualo con este test!
          <br />
        </Article>
        <Article className="center blue1 font-size-medium lineheight-verylarge">
          Y una vez que lo sepas, te diré qué es lo que puedes hacer para
          transformarlo.
          <br /> <br />
          {/* <div className="center blue1 bold font-size-medium"> 
        <br/> Recibirás en tu email consejos, ejercicios y herramientas esenciales con las que podrás:   
        </div> */}
        </Article>
        <Article layout="wide">
          <About
            titleClassName="tenor bold turkis-blue1 font-size-large  left lineheight-large"
            image={ImageConfianza2}
          >
            <div className="tenor bold turkis-blue1 font-size-large  left lineheight-large">
              Recibirás en tu email tips y herramientas esenciales con las que
              podrás:
            </div>
            <br />
            <br />
            {/* <ListCard   listItemIcon={<BsCheckCircle/>} className=" narrow left  font-size-medium lineheight-large"  
          // title=" Accede a este test de valoración y recibe en tu email consejos, ejercicios y herramientas esenciales con las que podrás: " 
          // titleClassName="center font-size-medium blue1 lineheight-large" 
          
          text={[ " Reconocer tus talentos naturales",
                  " Vencer tus dudas",
                  " Amplificar tu confianza",
                  " Empezar a lograr lo que te propones"]}/>  */}
            <BulletItem icon={<BsCheckCircle />}>
              {" "}
              Reconocer tus talentos naturales{" "}
            </BulletItem>
            <BulletItem icon={<BsCheckCircle />}> Vencer tus dudas </BulletItem>
            <BulletItem icon={<BsCheckCircle />}>
              {" "}
              Amplificar tu confianza{" "}
            </BulletItem>
            <BulletItem icon={<BsCheckCircle />}>
              {" "}
              Empezar a lograr lo que te propones{" "}
            </BulletItem>
            <br />
            <br />
            <div className="center blue1 font-size-medium">
              ¡Siente y disfruta de la diferencia!
            </div>
            <br />
            <div className="narrow tenor bold center font-size-verylarge turkis-blue1 lineheight-verylarge">
              ¡Te va a encantar!
            </div>
          </About>
        </Article>

        <Section color="turkis-blue1" small>
          <Article className="narrow tenor center font-size-medium white lineheight-large">
            Para poder hacerte llegar los resultados a tu email:
            <br /> introduce tus datos, marca la casilla y da click en el botón.
            <Newsletter tag="GIVEAWAY" value="survey" label="Comenzar Test" />
          </Article>
        </Section>
      </Section>
      <Footer />
    </>
  )
}
